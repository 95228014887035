<template>
  <div>
    <Header></Header>
    <div class="home-banner">
      <div class="all-title-ani">用先进技术帮助医疗机构</div>
      <div class="all-title-ani">提供高品质、可信赖的医疗务</div>
      <div class="all-title-ani">赋能医疗机构全方位数智化转型，实现高质量可持续发展，帮助医疗机构开源、节流、增效，从优秀到卓越。</div>
      <div class="all-title-ani all-ani-delay">进一步了解</div>
    </div>
    <div class="home">

      <div class="internet-box">
        <div class="internet-left" @click="$router.push('solution')">
          <div style="width: 3.7333rem;">互联网医疗智慧服务平台</div>
          <div>查看更多</div>
        </div>
        <div class="internet-right">
          <div>
            <div>电子病历管理系统</div>
            <div @click="$router.push('solution')">查看更多</div>
          </div>
          <div>
            <div>医学毛发检测系统</div>
            <div @click="$router.push('internet')">查看更多</div>
          </div>
          <div>
            <div>医疗互联网开发</div>
            <div @click="$router.push('medical')">查看更多</div>
          </div>
        </div>
      </div>
      <div class="why-choose">
        <div class="All-title">为什么选择云数智康</div>
        <div class="why-top" ref="why">
          <div>
            <div>
              <countTo ref="countTo1" :startVal="0" :endVal="100" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
              <span style="font-size:.48rem">%</span>
            </div>
            <div>服务水平</div>
          </div>
          <div>
            <div>
              <countTo ref="countTo2" :startVal="0" :endVal="95" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
              <span style="font-size:.48rem">%</span>
            </div>
            <div>研发和技术人员</div>
          </div>
          <div>
            <div>
              <countTo ref="countTo3" :startVal="0" :endVal="1000" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
              <span style="font-size:.48rem">+</span>
            </div>
            <div>医疗机构长期信赖</div>
          </div>
          <div>
            <div>
              <countTo ref="countTo4" :startVal="0" :endVal="3" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
              <span style="font-size:.48rem">+</span>
              <countTo ref="countTo5" :startVal="0" :endVal="1" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
            </div>
            <div>全面打造智慧医院</div>
          </div>
        </div>
        <div class="why-body">
          <div>
            <div>
              <img :src="`${$url}/home/4.png`" alt="">
              <img :src="`${$url}/home/20.png`" alt="">
              <div>团队稳定靠谱</div>
              <div>团队成员来自消费医疗行业专家和互联网营销专家，为客户提供专业的解决方案。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/5.png`" alt="">
              <img :src="`${$url}/home/22.png`" alt="">
              <div>注重服务口碑</div>
              <div>提供专业的信息安全技术服务力量和24小时售后服务团队，整体提高服务效能。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/6.png`" alt="">
              <img :src="`${$url}/home/23.png`" alt="">
              <div>产品创新迭代</div>
              <div>践行国家战略提倡推行的医疗信息化及“互联网+医疗”模式，助力医疗行业发展。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/7.png`" alt="">
              <img :src="`${$url}/home/21.png`" alt="">
              <div>安稳可信赖</div>
              <div>以云数智康数据中心资源为基础，提供一站式服务解决方案，多方面保障安全性。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="technical-advantages">
        <div class="All-title">技术优势</div>
        <div>
          <div>
            <div>
              <img :src="`${$url}/home/9.png`" alt="">
              <img :src="`${$url}/home/25.png`" alt="">
              <div>领先的技术能力</div>
              <div>人工智能、大数据、云计算，三大优势能力，推动技术进步，引领行业发展。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/10.png`" alt="">
              <img :src="`${$url}/home/26.png`" alt="">
              <div>全面的产品体系</div>
              <div>提供覆盖医疗基础设施建设、智慧医疗服务、智慧健康管理、智慧医学科普等智慧医疗健康产品和解决方案。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/11.png`" alt="">
              <img :src="`${$url}/home/27.png`" alt="">
              <div>丰富的生态资源</div>
              <div>创新科研、技术赋能、投资合作，构建覆盖医疗器械、医疗服务等全链条的医疗健康数字生态共同体。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/12.png`" alt="">
              <img :src="`${$url}/home/28.png`" alt="">
              <div>专注专业的服务水平</div>
              <div>依托云数智康的基础设施能力、人才优势与大数据、人工智能等技术积累，助力医疗大健康产业智慧化升级。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/13.png`" alt="">
              <img :src="`${$url}/home/29.png`" alt="">
              <div>雄厚的实力水平</div>
              <div>聚合云数智康AI团队以及同行业实验室，与国内医学专家、医疗机构共同研发智慧医疗健康创新应用。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/home/14.png`" alt="">
              <img :src="`${$url}/home/30.png`" alt="">
              <div>全面的医药新零售</div>
              <div>通过线上线下及公域私域相融合，提供强大技术支持和专业运营服务，帮助药店快速拥抱新零售并获得收益。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="news-updates">
        <div class="All-title">新闻动态</div>
        <div class="news-list">
          <div @click="$router.push({path:'info',query: { id: 6 }})">
            <div>智慧医院建设路上，必须关注的7大信息化评...</div>
            <div>智慧医院——一个近些年各大医院都在不断追求的目标。它并不是医院的新物种，而是在一般医院的基础上，利用各种新技术的应用支持，让医院医疗质量...</div>
            <div>2021-12-28</div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 2 }})">
            <div>“国考”绩效考核如何稳中取胜？</div>
            <div>公立医院“国考”步伐片刻未歇。继2021与2020年“国考”成绩公布时间仅三个月后，新一轮2022年公立三级、二级医院的绩效考核悄然启动。</div>
            <div>2023-02-01</div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 1 }})">
            <div>医院管理系统丨ODR助力PDCA循环，共同改善...</div>
            <div>近年来，在国家卫健委领导下，医院评审工作稳步推进，逐渐从“以结果为导向”向“以问题为导向”转变。也就是说，在评审过程中更注重发现...</div>
            <div>2023-02-09</div>
          </div>
        </div>
        <div class="see-more" @click="$router.push('news')">查看更多</div>
      </div>
      <div class="Partners">
        <div class="All-title">合作伙伴</div>
        <img :src="`${$url}/home/16.png`">
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import countTo from 'vue-count-to'
import '../style/swiper.css'
export default {
  components: {
    Header,
    Footer,
    countTo
  },
  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: this.listLenght, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll',this.handleScroll,true)
  },
  methods: {
    initSwiper() {
      const that = this
      this.mySwiper = new Swiper('.swiper-box',{
        direction: 'horizontal',
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000 // 1秒切换一次
        },
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            if (this.activeIndex == 5) {
              that.isMilepost = 1
            } else {
              that.isMilepost = this.activeIndex
            }
          }
        }
      })
    },
    isShow() {
      const el = this.$refs.why
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      const offsetTop = el.offsetTop
      const scollTop = document.documentElement.scrollTop
      const top = offsetTop - scollTop
      return top <= viewPortHeight && top >= 0
    },
    handleScroll() {
      if (this.isShow() && this.isEixed !== true) {
        this.$refs.countTo1.start()
        this.$refs.countTo2.start()
        this.$refs.countTo3.start()
        this.$refs.countTo4.start()
        this.$refs.countTo5.start()
        this.isEixed = true
      }
    }
  },
  mounted() {
    this.initSwiper()
    window.addEventListener('scroll',this.handleScroll,true)
  },
  data() {
    return {
      mySwiper: null,
      isEixed: false,
      isMilepost: 1
    }
  }
}
</script>

<style lang="scss">
.home {
  .internet-box {
    display: flex;
    color: #ffffff;
    .internet-left {
      width: 50%;
      background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/34.png')
        no-repeat;
      height: 480px;
      background-size: cover;
      background-position: center;
      > div:nth-child(1) {
        width: 100%;
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        line-height: 65px;
        margin: 120px auto 0;
      }
      > div:nth-child(2) {
        width: 188px;
        height: 78px;
        border-radius: 8px 8px 8px 8px;
        border: 2px solid #ffffff;
        text-align: center;
        line-height: 74px;
        margin: 40px auto 0;
        font-size: 28px;
      }
    }
    .internet-right {
      width: 50%;

      > div {
        height: 160px;
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/35.png')
          no-repeat;
        background-size: 105% 105%;
        background-position: right;
        color: #ffffff;
        overflow: hidden;
        > div:nth-child(1) {
          text-align: center;
          margin-top: 40px;
          font-size: 30px;
          font-weight: 500;
        }
        > div:nth-child(2) {
          font-size: 26px;
          font-weight: 400;
          text-align: center;
          margin-top: 30px;
          color: #ffffff;
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            border-top: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            position: absolute;
            top: 8px;
            right: 115px;
          }
        }
      }
      > div:nth-child(2) {
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/32.png')
          no-repeat;
        background-size: 105% 105%;
        background-position: right;
      }
      > div:nth-child(3) {
        background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/33.png')
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .why-choose {
    width: 100%;
    margin-top: -1px;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/3.png')
      no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-bottom: 90px;
    padding-top: 100px;

    .why-top {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;

      > div:nth-child(2n-1) {
        position: relative;

        &::after {
          position: absolute;
          right: -24px;
          top: 10px;
          display: block;
          content: '丨';
          font-size: 48px;
          font-weight: 300;
          color: #dddddd;
        }
      }

      > div {
        margin: 40px auto;
        text-align: center;
        width: 50%;

        > div:nth-child(1) {
          font-size: 64px;
          font-weight: bold;
          color: #181818;
          margin-bottom: 20px;

          span {
            font-size: 64px;
            font-weight: bold;
          }
        }

        > div:nth-child(2) {
          color: #7b7b7b;
          font-size: 28px;
        }
      }
    }
  }

  .technical-advantages {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/8.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 90px;
    padding-top: 100px;

    > div:nth-child(2) {
      margin-top: 64px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > div {
        position: relative;
        width: 340px;
        height: 424px;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-right: 22px;
        margin-bottom: 22px;
        text-align: center;
        &::after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          transition: all 0.5s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &::after {
            opacity: 1;
            background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/31.png')
              no-repeat;
            background-size: 100% 100%;
          }
          > div {
            img:nth-child(1) {
              display: none;
            }
            img:nth-child(2) {
              display: inline-block;
            }
            > div:nth-child(3) {
              color: #ffffff;
            }

            > div:nth-child(4) {
              color: #ffffff;
            }
          }
        }
        > div {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: 100%;
          height: 100%;

          img:nth-child(1) {
            width: 64px;
            height: 64px;
            margin-top: 40px;
          }
          img:nth-child(2) {
            width: 64px;
            height: 64px;
            margin-top: 40px;
            display: none;
          }

          > div:nth-child(3) {
            font-size: 30px;
            font-weight: 500;
            color: #181818;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          > div:nth-child(4) {
            width: 292px;
            font-size: 26px;
            font-weight: 400;
            color: #7b7b7b;
            line-height: 44px;
            margin: 0 auto;
          }
        }
      }

      & div:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .news-updates {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/home/15.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    .news-list {
      margin-top: 64px;

      > div {
        width: 702px;
        height: 282px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin: 0 auto 24px;
        padding: 32px 24px 0 24px;

        > div:nth-child(1) {
          font-size: 30px;
          font-weight: 500;
          color: #181818;
        }

        > div:nth-child(2) {
          width: 654px;
          font-size: 26px;
          font-weight: 400;
          color: #7b7b7b;
          line-height: 40px;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        > div:nth-child(3) {
          font-size: 26px;
          font-weight: 400;
          color: #7b7b7b;
        }
      }
    }

    .see-more {
      width: 188px;
      height: 78px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 2px solid #0b61c4;
      font-size: 30px;
      font-weight: 400;
      color: #0b61c4;
      line-height: 78px;
      text-align: center;
      margin: 48px auto 0;
    }
  }

  .Partners {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    background: #ffffff;
    img {
      margin-top: 48px;
      width: 702px;
    }
  }
}
</style>
